.cs-dashboard-question-settings-popup .mbsc-popup-padding {
    padding: 0!important;
}

.cs-dashboard-question-settings-main {
    background: white!important;
}

.cs-dashboard-question-settings-header-col {
    padding-left: 0!important;
    padding-right: 0!important;
}

.cs-dashboard-question-settings-header-row {
    margin-left: 0!important;
    margin-right: 0!important;
}

.cs-dashboard-question-settings-title-button {
    color: #0B7DBB!important;
    cursor: default;
}

.cs-dashboard-question-settings-close-button {
    color: #0B7DBB!important;
}

.cs-dashboard-question-settings-close-icon {
    height: 1.5em!important;
    color: #0B7DBB!important;
    padding-right: 0.5em;
    padding-top: 0.25em;
}

.cs-dashboard-question-settings-checkbox {
    color: #0B7DBB!important;
}

.cs-dashboard-question-settings-textarea .mbsc-textarea {
    height: 4.25em!important;
}

.cs-dashboard-question-settings-helper {
    margin-right: 1em;
    margin-left: 1em;
}

.cs-dashboard-question-settings-avatar {
    width: 6em;
    height: 6em;
    border-radius: 3em;
    background-size: contain;
    background-repeat: no-repeat;
    margin-bottom: 1.5em;
}
