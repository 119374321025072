.cs-dashboard-header-tab-rename-popup .mbsc-popup-padding {
    padding: 0;
}

.cs-dashboard-header-tab-rename-header-col {
    padding-left: 0!important;
    padding-right: 0!important;
}

.cs-dashboard-header-tab-rename-header-row {
    margin-left: 0!important;
    margin-right: 0!important;
}

.cs-dashboard-header-tab-rename-title-button {
    color: #0B7DBB!important;
    cursor: default;
}

.cs-dashboard-header-tab-rename-close-button {
    color: #0B7DBB!important;
}

.cs-dashboard-header-tab-rename-close-icon {
    height: 1.5em!important;
    color: #0B7DBB!important;
    padding-right: 0.5em;
    padding-top: 0.25em;
}
