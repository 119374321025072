.cs-dashboard-workspace-add-popup .mbsc-popup-padding {
    padding: 0!important;
}

.cs-dashboard-workspace-add-button {
    color: #0B7DBB!important;
}

.cs-dashboard-workspace-add-li {
    padding: .5em!important;
}
