.cs-dashboard-grid {
    margin-top: 7em;
    margin-bottom: 1.5em;
}

.cs-dashboard-grid-small {
    padding: 0!important;
    margin-top: 6em;
    margin-bottom: 1em;
}

.cs-dashboard-prompt {
    padding-top: 3em;
}


