.cs-dashboard-workspace-grid-header-secondary {
    background: white;
    margin-right: 0;
    margin-left: 0;
    border-bottom-color: #ccc;
    border-bottom-width: thin;
    border-bottom-style: solid;
}

.cs-dashboard-workspace-grid-color {
    color: #0B7DBB!important;
}

.cs-dashboard-workspace-grid-icon {
    height: 1.5em!important;
}

.cs-dashboard-workspace-grid-col-end {
    padding-right: .5em!important;
}

.cs-dashboard-workspace-grid-charts {
    background: white;
    margin-right: 0;
    margin-left: 0;
}

.cs-dashboard-workspace-grid-update {
    background: #0FBEDB!important;
}

.cs-dashboard-workspace-grid-col {
    padding-right: 0!important;
    padding-left: 0!important;
}

.cs-dashboard-workspace-grid-refresh {
    background: #f7f7f7;
    margin-right: 0!important;
    margin-left: 0!important;
    cursor: text;
    outline: none;
}
