.cs-dashboard-workspace-generate-popup .mbsc-popup-padding {
    padding: 0;
}

.cs-dashboard-workspace-generate-helper {
    margin-right: 1em;
    margin-left: 1em;
}

.cs-dashboard-workspace-generate-textarea .mbsc-textarea {
    height: 4.25em!important;
}

.cs-dashboard-workspace-generate-image {
    height: 15em;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.cs-dashboard-workspace-generate-generated-icon {
    height: 1.5em!important;
    color: #0B7DBB;
    margin-right: 1em!important;
    min-width: 1.75em!important;
    margin-top: .25em!important;
    margin-bottom: -.25em!important;
}

.cs-dashboard-workspace-generate-generated-button {
    background: white!important;
}

.cs-dashboard-workspace-generate-header-col {
    padding-left: 0!important;
    padding-right: 0!important;
}

.cs-dashboard-workspace-generate-header-row {
    margin-left: 0!important;
    margin-right: 0!important;
}

.cs-dashboard-workspace-generate-title-button {
    color: #0B7DBB!important;
    cursor: default;
}

.cs-dashboard-workspace-generate-close-button {
    color: #0B7DBB!important;
}

.cs-dashboard-workspace-generate-close-icon {
    height: 1.5em!important;
    color: #0B7DBB!important;
    padding-right: 0.5em;
    padding-top: 0.25em;
}

.cs-dashboard-workspace-generate-nav-buttons {
    margin: 0!important;
    border-width: 0!important;
}

.cs-dashboard-workspace-generate-nav-button {
    background: #0B7DBB!important;
    color: white!important;
    margin: 0!important;
    border-radius: 0!important;
    line-height: 2.75em!important;
}
