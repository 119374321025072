.cs-dashboard-calendar-display-popup .mbsc-popup-padding {
    padding: 0!important;
}

.cs-dashboard-calendar-display-button {
    color: #12B1B9!important;
}

.cs-dashboard-calendar-display-li {
    padding: .5em!important;
}
