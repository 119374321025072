.cs-dashboard-header-mobile-popup .mbsc-popup-padding {
    padding: 0!important;
}

.cs-dashboard-header-mobile-main {
    background: white!important;
}

.cs-dashboard-header-mobile-header-col {
    padding-left: 0!important;
    padding-right: 0!important;
}

.cs-dashboard-header-mobile-header-row {
    margin-left: 0!important;
    margin-right: 0!important;
}

.cs-dashboard-header-mobile-title-button {
    color: #0B7DBB!important;
    cursor: default;
}

.cs-dashboard-header-mobile-close-button {
    color: #0B7DBB!important;
}

.cs-dashboard-header-mobile-close-icon {
    height: 1.5em!important;
    color: #0B7DBB!important;
    padding-right: 0.5em;
    padding-top: 0.25em;
}

.cs-dashboard-header-mobile-checkbox {
    color: #0B7DBB!important;
}

.cs-dashboard-header-mobile-textarea .mbsc-textarea {
    height: 4.25em!important;
}

.cs-dashboard-header-mobile-helper {
    margin-right: 1em;
    margin-left: 1em;
}

.cs-dashboard-header-mobile-nav-buttons {
    margin: 0!important;
    border-width: 0!important;
}

.cs-dashboard-header-mobile-nav-button {
    background: #0B7DBB!important;
    color: white!important;
    margin: 0!important;
    border-radius: 0!important;
    line-height: 2.75em!important;
}

.cs-dashboard-header-mobile-avatar {
    width: 6em;
    height: 6em;
    border-radius: 3em;
    background-size: contain;
    background-repeat: no-repeat;
    margin-bottom: 1.5em;
}
