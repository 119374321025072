.cs-dashboard-question-users-popup .mbsc-popup-padding {
    padding: 0!important;
}

.cs-dashboard-question-users-helper {
    margin-right: 1em;
    margin-left: 1em;
}

.cs-dashboard-question-users-header-col {
    padding-left: 0!important;
    padding-right: 0!important;
}

.cs-dashboard-question-users-header-row {
    margin-left: 0!important;
    margin-right: 0!important;
}

.cs-dashboard-question-users-title-button {
    color: #0B7DBB!important;
    cursor: default;
}

.cs-dashboard-question-users-close-button {
    color: #0B7DBB!important;
}

.cs-dashboard-question-users-close-icon {
    height: 1.5em!important;
    color: #0B7DBB!important;
    padding-right: 0.5em;
    padding-top: 0.25em;
}
