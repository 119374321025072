.cs-dashboard-info-popup .mbsc-popup-padding {
    padding-right: 0;
    padding-left: 0;
    padding-top: 1em;
    padding-bottom: 1em;
}

.cs-dashboard-info-button {
    cursor: default!important;
}
