.cs-dashboard-workspace-text-header-secondary {
    background: white;
    margin-right: 0;
    margin-left: 0;
    border-bottom-color: #ccc;
    border-bottom-width: thin;
    border-bottom-style: solid;
}

.cs-dashboard-workspace-text-color {
    color: #0B7DBB!important;
}

.cs-dashboard-workspace-text-col-end {
    padding-right: .5em!important;
}

.cs-dashboard-workspace-text-section {
    background: white;
    margin-right: 0;
    margin-left: 0;
}

.cs-dashboard-workspace-text-editor {
    padding-right: 0!important;
    padding-left: 0!important;
}

.cs-dashboard-workspace-text-update {
    background: #0FBEDB!important;
}
