.cs-dashboard-header-tools-popup .mbsc-popup-padding {
    padding: 0!important;
}

.cs-dashboard-header-tools-helper {
    margin-right: 1em;
    margin-left: 1em;
}

.cs-dashboard-header-tools-header-col {
    padding-left: 0!important;
    padding-right: 0!important;
}

.cs-dashboard-header-tools-header-row {
    margin-left: 0!important;
    margin-right: 0!important;
}

.cs-dashboard-header-tools-title-button {
    color: #0B7DBB!important;
    cursor: default;
}

.cs-dashboard-header-tools-close-button {
    color: #0B7DBB!important;
}

.cs-dashboard-header-tools-close-icon {
    height: 1.5em!important;
    color: #0B7DBB!important;
    padding-right: 0.5em;
    padding-top: 0.25em;
}
