.cs-dashboard-workspace-graph-header-secondary {
    background: white;
    margin-right: 0;
    margin-left: 0;
    border-bottom-color: #ccc;
    border-bottom-width: thin;
    border-bottom-style: solid;
}

.cs-dashboard-workspace-graph-color {
    color: #0B7DBB!important;
}

.cs-dashboard-workspace-graph-col-end {
    padding-right: .5em!important;
}

.cs-dashboard-workspace-graph-charts {
    background: white;
    margin-right: 0;
    margin-left: 0;
}

.cs-dashboard-workspace-graph-update {
    background: #0FBEDB!important;
}
