.cs-dashboard-workspace-todo-header-secondary {
    background: white;
    margin-right: 0;
    margin-left: 0;
    border-bottom-color: #ccc;
    border-bottom-width: thin;
    border-bottom-style: solid;
}

.cs-dashboard-workspace-todo-color {
    color: #0B7DBB!important;
}

.cs-dashboard-workspace-todo-col-end {
    padding-right: .5em!important;
}

.cs-dashboard-workspace-todo-section {
    background: white;
    margin-right: 0;
    margin-left: 0;
}

.cs-dashboard-workspace-todo-update {
    background: #0FBEDB!important;
}

.cs-dashboard-workspace-todo-list {
    margin-left: 0;
    margin-right: 0;
}

.cs-dashboard-workspace-todo-icon {
    height: 2em!important;
    color: #376e9f!important;
}

.cs-dashboard-workspace-todo-list-section {
    margin-left: 1em;
}

.cs-dashboard-workspace-todo-item-txt {
    padding-left: 1em!important;
    margin-top: 0;
}

.cs-dashboard-workspace-todo-pipe {
    height: 1em!important;
    color: #0B7DBB!important;
    margin-left: .5em!important;
    margin-right: .5em!important;
    margin-top: .1em!important;
}

.cs-dashboard-workspace-todo-header-col {
    padding-left: 0!important;
    padding-right: 0!important;
}

.cs-dashboard-workspace-todo-select {
    padding-top: .25em!important;
    padding-bottom: .25em!important;
}

.cs-dashboard-workspace-todo-select .mbsc-form-control-wrapper:before {
    border-top: 0 solid white!important;
}

.cs-dashboard-workspace-todo-select .mbsc-form-control-wrapper:after {
    border-top: 0 solid white!important;
}

.cs-dashboard-workspace-todo-sort-button {
    height: 1.5em!important;
    color: #0B7DBB!important;
}
