.raw-html-embed {/*CKEditor Grid Skill*/
    display: none!important;
}

.mbsc-popup-header {
    color: #0B7DBB!important;
}

.mbsc-ios .mbsc-lv-ic-m {
    color: white!important;
    font-weight: bold;
    font-size: 1em;
}

.mbsc-ios.mbsc-input .mbsc-label {
    color: #191F3B!important;
}

.mbsc-ios.mbsc-label, .mbsc-ios.mbsc-textfield {
    color: #191F3B!important;
}

.mbsc-calendar-header {
    background: white!important;
}

.mbsc-schedule-date-header {
    background: white!important;
}

.ck-editor__editable_inline {
    min-height: 6em;
}

.ck-content {
    line-height: 1.6;
    word-break: break-word;
}
