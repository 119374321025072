.cs-login-register-popup .mbsc-popup-padding {
    padding: 0;
}

.cs-login-register-save {
    color: white!important;
    background: #0B7DBB!important;
    font-weight: bold;
}

.cs-login-register-helper {
    margin-right: 1em;
    margin-left: 1em;
}

.cs-login-register-grid {
    padding-left: 0!important;
    padding-right: 0!important;
}

.cs-login-register-inputs-left {
    padding-right: 0!important;
}

.cs-login-register-inputs-right {
    padding-left: 0!important;
}

.cs-login-register-inputs-right-input {
    margin-left: 0!important;
}
