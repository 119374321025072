.cs-dashboard-workspace-code-header-secondary {
    background: white;
    margin-right: 0;
    margin-left: 0;
    border-bottom-color: #ccc;
    border-bottom-width: thin;
    border-bottom-style: solid;
}

.cs-dashboard-workspace-code-color {
    color: #0B7DBB!important;
}

.cs-dashboard-workspace-code-col-end {
    padding-right: .5em!important;
}

.cs-dashboard-workspace-code-section {
    background: white;
    margin-right: 0;
    margin-left: 0;
}

.cs-dashboard-workspace-code-editor {
    padding: 0!important;
}

.cs-dashboard-workspace-code-editor-text {
    background: #2b2d30!important;
}

.cs-dashboard-workspace-code-editor-text .mbsc-textarea {
    color: white!important;
}

.cs-dashboard-workspace-code-update {
    background: #0FBEDB!important;
}
