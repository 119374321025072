.cs-dashboard-workspace-image-header-secondary {
    background: white;
    margin-right: 0;
    margin-left: 0;
    border-bottom-color: #ccc;
    border-bottom-width: thin;
    border-bottom-style: solid;
}

.cs-dashboard-workspace-image-color {
    color: #0B7DBB!important;
}

.cs-dashboard-workspace-image-col-end {
    padding-right: .5em!important;
}

.cs-dashboard-workspace-image-section {
    background: white;
    margin-right: 0;
    margin-left: 0;
}

.cs-dashboard-workspace-image-editor {
    padding: 0!important;
}

.cs-dashboard-workspace-image-update {
    background: #0FBEDB!important;
}

.cs-dashboard-workspace-image-canvas {
    height: 30em;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
