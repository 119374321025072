.cs-header-toolbar {
  position: fixed;
  background: white;
  z-index: 9999;
  padding: 0!important;
  border-bottom-color: #ccc;
  border-bottom-width: thin;
  border-bottom-style: solid;
}

.cs-header-logo {
  width: 3em;
  height: 2.5em;
  margin-top: .3em;
  background-size: contain;
  background-repeat: no-repeat;
}

.cs-header-col-start {
  padding-left: 2em!important;
}

.cs-header-col-end {
  padding-right: 1.5em!important;
}

.cs-header-button {
  color: #0B7DBB!important;
}

.cs-header-icon {
  height: 1.5em!important;
  padding-right: 0.75em;
}
