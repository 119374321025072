.cs-dashboard-items-popup .mbsc-popup-padding {
    padding: 0;
}

.cs-dashboard-items-list {
    margin-top: .5em;
    margin-left: 0;
    margin-right: 0;
}

.cs-dashboard-items-list-icon {
    height: 2.5em!important;
    margin-right: 0!important;
    margin-top: 0!important;
    margin-bottom: 0!important;
    margin-left: .25em!important;
    color: #376e9f!important;
}

.cs-dashboard-items-list-section {
    margin-left: 1em;
    padding-top: .5em!important;
}

.cs-dashboard-items-item-txt {
    padding-left: 1em!important;
    margin-top: 0;
}

.cs-dashboard-items-helper {
    margin-right: 1em;
    margin-left: 1em;
}

.cs-dashboard-items-header-col {
    padding-left: 0!important;
    padding-right: 0!important;
}

.cs-dashboard-items-header-row {
    margin-left: 0!important;
    margin-right: 0!important;
}

.cs-dashboard-items-title-button {
    color: #0B7DBB!important;
    cursor: default;
}

.cs-dashboard-items-close-button {
    color: #0B7DBB!important;
}

.cs-dashboard-items-close-icon {
    height: 1.5em!important;
    color: #0B7DBB!important;
    padding-right: 0.5em;
    padding-top: 0.25em;
}

.cs-dashboard-items-scroll {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100% - 100px);
}

.cs-dashboard-items-select .mbsc-form-control-wrapper:before {
    border-top: 0 solid white!important;
}

.cs-dashboard-items-col-sort {
    padding-left: 0!important;
    padding-right: 0!important;
    background: white!important;
    border-bottom: thin solid #ccc!important;
}

.cs-dashboard-items-sort-button {
    height: 1.5em!important;
    color: #0B7DBB!important;
}

.cs-dashboard-items-pipe {
    height: 1em!important;
    color: #0B7DBB!important;
    margin-left: .5em!important;
    margin-right: .5em!important;
    margin-top: .1em!important;
}
