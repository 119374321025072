.cs-dashboard-calendar-header {
    margin-top: 1em;
    background: #f7f7f7;
    margin-right: 0!important;
    margin-left: 0!important;
    border-bottom-color: #ccc;
    border-bottom-width: thin;
    border-bottom-style: solid;
}

.cs-dashboard-calendar-submenu {
    background: #f7f7f7;
    margin-right: 0!important;
    margin-left: 0!important;
    border-bottom-color: #ccc;
    border-bottom-width: thin;
    border-bottom-style: solid;
}

.cs-dashboard-calendar-main {
    background: white;
    margin-right: 0!important;
    margin-left: 0!important;
}

.cs-dashboard-calendar-icon {
    height: 1.5em!important;
}

.cs-dashboard-calendar-icon-pipe {
    cursor: default!important;
}

.cs-dashboard-calendar-color {
    color: #12B1B9!important;
}

.cs-dashboard-calendar-menu-text {
    padding-left: .75em!important;
}

.cs-dashboard-calendar-event-location {
    padding: 0 10px;
}

.cs-dashboard-calendar-col-end {
    padding-right: .5em!important;
}

.cs-dashboard-calendar-col-start {
    padding-left: .5em!important;
}

.cs-dashboard-calendar-picker input {
    border-width: 0!important;
    color: #12B1B9!important;
    font-weight: bold!important;
    font-size: 1em!important;
    text-align: end!important;
}

.cs-dashboard-calendar-col {
    padding-left: 0!important;
    padding-right: 0!important;
}
