.cs-dashboard-header-conversations-popup .mbsc-popup-padding {
    padding: 0!important;
}

.cs-dashboard-header-conversations-item-txt {
    padding-left: 1em!important;
    margin-top: 0;
}

.cs-dashboard-header-conversations-header-col {
    padding-left: 0!important;
    padding-right: 0!important;
}

.cs-dashboard-header-conversations-header-row {
    margin-left: 0!important;
    margin-right: 0!important;
}

.cs-dashboard-header-conversations-title-button {
    color: #0B7DBB!important;
    cursor: default;
}

.cs-dashboard-header-conversations-close-button {
    color: #0B7DBB!important;
}

.cs-dashboard-header-conversations-close-icon {
    height: 1.5em!important;
    color: #0B7DBB!important;
    padding-right: 0.5em;
    padding-top: 0.25em;
}

.cs-dashboard-header-conversations-sort-button {
    height: 1.5em!important;
    color: #0B7DBB!important;
}

.cs-dashboard-header-conversations-col-sort {
    padding-left: 0!important;
    padding-right: 0!important;
    background: white!important;
    border-bottom: thin solid #ccc!important;
}

.cs-dashboard-header-conversations-select .mbsc-form-control-wrapper:before {
    border-top: 0 solid white!important;
}

.cs-dashboard-header-conversations-scroll {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100% - 95px);
}
