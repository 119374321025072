.cs-dashboard-grid-add-popup .mbsc-popup-padding {
    padding: 0!important;
}

.cs-dashboard-grid-add-button {
    color: #0B7DBB!important;
}

.cs-dashboard-grid-add-li {
    padding: .5em!important;
}

.cs-dashboard-grid-add-icon-disabled {
    height: 1.5em!important;
    margin-right: 1em!important;
}

.cs-dashboard-grid-add-icon {
    height: 1.5em!important;
    color: #12B1B9;
    margin-right: 1em!important;
}
