.cs-dashboard-header-tabs-popup .mbsc-popup-padding {
    padding: 0!important;
}

.cs-dashboard-header-tabs-li {
    padding: .5em!important;
}

.cs-dashboard-header-tabs-button {
    color: #0B7DBB!important;
}
