.cs-dashboard-workspace-doc-header-secondary {
    background: white;
    margin-right: 0;
    margin-left: 0;
    border-bottom-color: #ccc;
    border-bottom-width: thin;
    border-bottom-style: solid;
}

.cs-dashboard-workspace-doc-color {
    color: #0B7DBB!important;
}

.cs-dashboard-workspace-doc-col-end {
    padding-right: .5em!important;
}

.cs-dashboard-workspace-doc-section {
    background: white;
    margin-right: 0;
    margin-left: 0;
}

.cs-dashboard-workspace-doc-editor {
    padding-right: 0!important;
    padding-left: 0!important;
}

.cs-dashboard-workspace-doc-update {
    background: #0FBEDB!important;
}

.cs-dashboard-workspace-doc-editor-main {
    --ckeditor5-preview-height: 700px;
}

.cs-dashboard-workspace-doc-editor-container__editor-wrapper {
    display: flex;
}

.cs-dashboard-workspace-doc-editor-container_document-editor {
    border: 1px solid var(--ck-color-base-border);
}

.cs-dashboard-workspace-doc-editor-container_document-editor .cs-dashboard-workspace-doc-editor-container__toolbar {
    display: flex;
    position: relative;
    box-shadow: 0 2px 3px hsla(0, 0%, 0%, 0.078);
}

.cs-dashboard-workspace-doc-editor-container_document-editor .cs-dashboard-workspace-doc-editor-container__toolbar > .ck.ck-toolbar {
    flex-grow: 1;
    width: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-top: 0;
    border-left: 0;
    border-right: 0;
}

.cs-dashboard-workspace-doc-editor-container_document-editor .cs-dashboard-workspace-doc-editor-container__menu-bar > .ck.ck-menu-bar {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-top: 0;
    border-left: 0;
    border-right: 0;
}

.cs-dashboard-workspace-doc-editor-container_document-editor .cs-dashboard-workspace-doc-editor-container__editor-wrapper {
    max-height: var(--ckeditor5-preview-height);
    min-height: var(--ckeditor5-preview-height);
    overflow-y: scroll;
    background: var(--ck-color-base-foreground);
}

.cs-dashboard-workspace-doc-editor-container_document-editor .cs-dashboard-workspace-doc-editor-container__editor {
    margin-top: 28px;
    margin-bottom: 28px;
    height: 100%;
}

.cs-dashboard-workspace-doc-editor-container_document-editor .cs-dashboard-workspace-doc-editor-container__editor .ck.ck-editor__editable {
    box-sizing: border-box;
    min-width: calc(210mm + 2px);
    max-width: calc(210mm + 2px);
    min-height: 297mm;
    height: fit-content;
    padding: 20mm 12mm;
    border: 1px hsl(0, 0%, 82.7%) solid;
    background: hsl(0, 0%, 100%);
    box-shadow: 0 2px 3px hsla(0, 0%, 0%, 0.078);
    flex: 1 1 auto;
    margin-left: 72px;
    margin-right: 72px;
}
