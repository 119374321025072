.cs-dashboard-conversation-main {
    background: white;
    margin-right: 0;
    margin-left: 0;
}

.cs-dashboard-conversation-editor {
    padding-right: .75em!important;
    padding-left: .75em!important;
}

.cs-dashboard-conversation-header {
    margin-top: 1em;
    background: #f7f7f7;
    margin-right: 0!important;
    margin-left: 0!important;
    border-bottom-color: #ccc;
    border-bottom-width: thin;
    border-bottom-style: solid;
}

.cs-dashboard-conversation-col {
    padding-right: 0!important;
    padding-left: 0!important;
}

.cs-dashboard-conversation-col-start {
    padding-left: .5em!important;
}

.cs-dashboard-conversation-color {
    color: #0B7DBB!important;
}

.cs-dashboard-conversation-icon {
    height: 1.5em!important;
}

.cs-dashboard-conversation-button-pipe {
    cursor: default!important;
}

.cs-dashboard-conversation-col-end {
    padding-right: .5em!important;
}

.cs-dashboard-conversation-menu-text {
    padding-left: .75em!important;
}

.cs-dashboard-conversation-avatar {
    width: 2em;
    height: 2em;
    border-radius: 1em;
    background-size: contain;
    background-repeat: no-repeat;
}

.cs-dashboard-conversation-avatar-xs {
    width: 1.5em;
    height: 1.5em;
    border-radius: .75em;
    background-size: contain;
    background-repeat: no-repeat;
}
