.cs-dashboard-question-main {
    background: white;
    margin-right: 0;
    margin-left: 0;
}

.cs-dashboard-question-badge {
    position: relative;
    display: inline-block;
    line-height: 1.75em!important;
    padding-top: .25em!important;
}

.cs-dashboard-question-badge-icon {
    position: absolute;
    top: 1.5em;
    right: -0.25em;
    background-color: #ff4444;
    color: white;
    border-radius: 50%;
    min-width: 1.5em;
    height: 1.5em;
    font-size: .65em;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}

.cs-dashboard-question-header {
    margin-top: 1em;
    background: #f7f7f7;
    margin-right: 0!important;
    margin-left: 0!important;
    border-bottom-color: #ccc;
    border-bottom-width: thin;
    border-bottom-style: solid;
}

.cs-dashboard-question-col-end {
    padding-right: .5em!important;
}

.cs-dashboard-question-col-start {
    padding-left: .5em!important;
}

.cs-dashboard-question-icon {
    height: 1.5em!important;
}

.cs-dashboard-question-color {
    color: #0B7DBB!important;
}

.cs-dashboard-question-menu-text {
    padding-left: .75em!important;
}

.cs-dashboard-question-body {
    padding-right: 0!important;
    padding-left: 1.75em!important;
}

.cs-dashboard-question-footer {
    background: white;
    margin-right: 0!important;
    margin-left: 0!important;
    border-top-color: #ccc;
    border-top-width: thin;
    border-top-style: solid;
}

.cs-dashboard-question-select {
    padding: 0!important;
    max-height: 52px!important;
    overflow: hidden!important;
}

.cs-dashboard-question-select .mbsc-form-control-wrapper:before {
    border-bottom: 0 solid white!important;
    border-top: thin solid #ccc!important;
}

.cs-dashboard-question-select .mbsc-form-control-wrapper:after {
    border-bottom: 0 solid white!important;
    border-top: 0 solid white!important;
}

.cs-dashboard-question-snackbar .mbsc-toast-background {
    background: #0B7DBB!important;
}

.cs-dashboard-question-avatar {
    width: 2em;
    height: 2em;
    border-radius: 1em;
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: .6em!important;
}

.cs-dashboard-question-avatar-active {
    box-shadow: 0 0 0 2px #fff,
    0 0 0 4px #3498db,
    0 0 10px rgba(52, 152, 219, 0.5);
}

@keyframes pulse-highlight {
    0% {
        box-shadow: 0 0 0 0 rgba(25, 118, 210, 0.7);
    }
    70% {
        box-shadow: 0 0 0 8px rgba(25, 118, 210, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(25, 118, 210, 0);
    }
}

@keyframes pulse-highlight-xs {
    0% {
        box-shadow: 0 0 0 0 rgba(25, 118, 210, 0.7);
    }
    70% {
        box-shadow: 0 0 0 6px rgba(25, 118, 210, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(25, 118, 210, 0);
    }
}

.cs-dashboard-question-avatar-pulse {
    animation: pulse-highlight 1s infinite;
}

.cs-dashboard-question-avatar-pulse-xs {
    animation: pulse-highlight 1s infinite;
}

.cs-dashboard-question-avatar-xs {
    width: 1.5em;
    height: 1.5em;
    border-radius: .75em;
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: .5em!important;
}

.cs-dashboard-question-avatar-text {
    width: 1.6em;
    height: 1.6em;
    border-radius: .8em;
    background-color: #0B7DBB;
    margin-top: .5em!important;
    padding-top: .25em!important;
    color: white;
}

.cs-dashboard-question-avatar-text-xs {
    width: 2em;
    height: 2em;
    border-radius: 1em;
    background-color: #0B7DBB;
    margin-top: .75em!important;
    padding-top: .4em!important;
    color: white;
}
