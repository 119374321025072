.cs-dashboard-workspace-header {
    margin-top: 1em;
    background: #f7f7f7;
    margin-right: 0!important;
    margin-left: 0!important;
    border-bottom-color: #ccc;
    border-bottom-width: thin;
    border-bottom-style: solid;
}

.cs-dashboard-workspace-col-start {
    padding-left: .5em!important;
}

.cs-dashboard-workspace-menu-text {
    padding-left: .75em!important;
}

.cs-dashboard-workspace-color {
    color: #0B7DBB!important;
}

.cs-dashboard-workspace-icon {
    height: 1.5em!important;
}

.cs-dashboard-workspace-icon-update {
    height: 1.5em!important;
    color: #ff4444!important;
}

.cs-dashboard-workspace-col-end {
    padding-right: .5em!important;
}

.cs-dashboard-workspace-main {
    background: #f7f7f7;
    margin-right: 0!important;
    margin-left: 0!important;
    cursor: text;
    outline: none;
}

.cs-dashboard-workspace-cursor {
    cursor: pointer;
}

.cs-dashboard-workspace-title {
    font-size: 1.25em!important;
}

.cs-dashboard-workspace-footer {
    padding: .5em!important;
}
