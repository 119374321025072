.cs-dashboard-header-user-popup .mbsc-popup-padding {
    padding: 0;
}

.cs-dashboard-header-user-avatar {
    width: 5em;
    height: 5em;
    border-radius: 2.5em;
    background-size: contain;
    background-repeat: no-repeat;
}

.cs-dashboard-header-user-link a {
    color: #0FBEDB!important;
}

.cs-dashboard-header-user-header-col {
    padding-left: 0!important;
    padding-right: 0!important;
}

.cs-dashboard-header-user-header-row {
    margin-left: 0!important;
    margin-right: 0!important;
}

.cs-dashboard-header-user-title-button {
    color: #0B7DBB!important;
    cursor: default;
}

.cs-dashboard-header-user-close-button {
    color: #0B7DBB!important;
}

.cs-dashboard-header-user-close-icon {
    height: 1.5em!important;
    color: #0B7DBB!important;
    padding-right: 0.5em;
    padding-top: 0.25em;
}

.cs-dashboard-header-user-nav-buttons {
    margin: 0!important;
    border-width: 0!important;
}

.cs-dashboard-header-user-nav-button {
    background: #0B7DBB!important;
    color: white!important;
    margin: 0!important;
    border-radius: 0!important;
    line-height: 2.75em!important;
}

.cs-dashboard-header-user-info-button {
    cursor: default;
}
